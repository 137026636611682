@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html,body{
  background: url(/static/media/BG_Overlay.8b762c4b.png) no-repeat 0 50%;
  background-repeat: repeat,repeat;
  background-size: cover;
  background-color:#0B0B0B;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

::selection{
  color: #fff;
  background: #5372F0;
}

.wrapper{
  width: 496px;
  background: #17181B;
  border-radius: 10px;
  border: 1px solid #545454;
  color: #fff;
  padding: 18px 25px 20px;
  box-shadow: 0 0 30px rgba(0,0,0,0.06);
}

.wrapper :where(.title, li, li i, .details){
  display: flex;
  align-items: center;
}
.title img{
  max-width: 21px;
}
.title h2{
  font-size: 21px;
  font-weight: 600;
  margin-left: 8px;
}
.wrapper .content{
  margin: 10px 0;
}
.content p{
  font-size: 15px;
}
.content ul{
  display: flex;
  flex-wrap: wrap;
  padding: 7px;
  margin: 12px 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='11' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 5px;
  background-color: #0B0B0B;
}
.content ul  li{
  color: #fff;
  margin: 4px 3px;
  list-style: none;
  border-radius: 5px;
  background: #181818;
  padding: 5px 8px 5px 10px;
  border: 1px solid #545454;
}
.content ul li i{
  height: 20px;
  width: 20px;
  color: #808080;
  margin-left: 8px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 50%;
  background: #dfdfdf;
  justify-content: center;
}
.content ul input{
  flex: 1 1;
  padding: 5px;
  border: none;
  outline: none;
  font-size: 16px;
  color: #fff;
  background: #0B0B0B;
}
.wrapper .details{
  justify-content: space-between;
}
.details button{
  border: none;
  outline: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  padding: 12px 15px;
  border-radius: 5px;
  background: #623CEA;
  transition: 0.3s ease;
}
.details button:hover{
  background: #49329b;
}

.details button:active{
  background: #49329b;
  box-shadow: 0 0 0 .4rem rgba(98, 60, 234, 0.2);
}

.container{
  width: 100%;
}

.swiper {
  width: 400px;
  height: auto;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  color: #fff;
  background-color: rgb(0, 0, 0);
  border: 1px solid #454545;
}

.template-container{
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: rgb(10, 10, 10);
  border-radius: 10px;
}

.template-header{
  height: 55px;
  align-items: center;
}

.template-profile{
  top: 15px;
  left: 15px;
  float: left;
  position: relative;
  /* padding: 15px 15px 10px 15px; */
}

.template-account-name{
  height: 100%;
  margin-top: 5px;
  margin-left: 20px;
  float: left;
  position: relative;
}

.template-account-name-text{
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}


.template-options img{
  top: 20px;
  right: 15px;
  position: fixed;
  width: 4px;
}

.template-account-name{
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
}

.template-image img{
  width: 400px;
  height: 400px;
  /* overflow: hidden; */
  object-fit: cover;
}

.interact{
  padding: 0px 15px;
}

.interact-icon{
  padding: 7px;
}

.like-icon{
  cursor: pointer;
}

.bookmark-icon{
  float: right;
  clear: right;
}

.likes h1{
  padding-left: 20px;
  font-size: 17px;
  font-weight: 200;
  font-style: normal;
  color: #fff;
}

.caption p{
  padding-left: 20px;
  font-size: 17px;
  font-weight: 400;
  font-style: normal;
  color: #fff;
}

.caption p span{
  font-size: 17px;
  font-weight: 200;
  font-style: normal;
  color: #fff;
}

.post-time p{
  padding: 10px 5px 10px 25px;
  font-size: 12px;
  font-weight: 200;
  font-style: normal;
  color: #fff;
}

@media (max-width:786px){
  .wrapper{
    width: 350px;
  }
  .swiper {
    width: 350px;
    height: auto;
  }
  .swiper-slide-img{
    width: 280px;
    height: 420px;
  }
}
